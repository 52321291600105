<template>
    <section>
        <div
            v-for="(event, i) in modelValue"
            :key="`event-${event.guid}`"
            class="shop-tickets__groups__event ot-mb[md] ot-py[lg]"
        >
            <div class="shop-tickets__groups__event--header ot-mb[md]">
                <div class="shop-tickets__groups__event--header-left">
                    <DateAsset
                        v-if="event.start"
                        :date="new Date(event.start)"
                    />
                    <div>
                        <h3 class="ot-mb[xs]">
                            {{ event.name }}
                        </h3>
                        <p class="ot-text-small">
                            <span v-if="event.start && event.end">
                                {{ $l.dateRangeLong(event.start, event.end) }}
                            </span>
                            <br>
                            <span v-if="event.location">
                                <OtIcon
                                    size="tiny"
                                    type="location"
                                />
                                {{ event.location.name }} ({{ event.location.address }})
                            </span>
                        </p>
                    </div>
                </div>
                <!-- TODO implement later
                    <div>
                        <button class="ot-button is-danger is-small" @click="removeEventFromShop(event.guid)" disabled>
                            <OtIcon type="close" class="ot-button-icon--left" />
                            Remove event from shop
                        </button>
                    </div> -->
            </div>

            <DragAreaTicketCollapses
                :event="event"
                :collapsed-tickets="event.items"
                @remove:ticketFromShop="onRemoveTicket"
                @update:nestedCollapse="onUpdateNestedCollapse"
                @remove:conceptCollapse="conceptCollapseId => onRemoveConceptCollapse(conceptCollapseId, i)"
                @open-edit:collapse="_collapse => emit('open-modal:updateCollapse', _collapse, i)"
                @move:up="emit('move:up', $event)"
                @move:down="emit('move:down', $event)"
                @link="emit('link', $event)"
                @unlink="emit('unlink', $event)"
            />

            <div class="shop-tickets__groups__event--actions ot-pt[lg]">
                <button
                    class="ot-button is-outline is-small ot-mr[sm] "
                    @click="createCollapseInEvent(event.guid)"
                >
                    <OtIcon
                        type="plus"
                        size="small"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.shop_tickets.create_group.text') }}
                </button>
                <button
                    class="ot-button is-outline is-small"
                    @click="emit('open-modal:addTickets', event.guid)"
                >
                    <OtIcon
                        type="plus"
                        size="small"
                        class="ot-button-icon--left"
                    />
                    {{ $t('dashboard.shop_tickets.add_tickets.text') }}
                </button>
            </div>
        </div>
    </section>
</template>

<script lang="ts" setup>
import DragAreaTicketCollapses from './DragAreaTicketCollapses.vue';
import type { ShopDataCollapse, ShopDataEvent } from '../../../../services/shop';
import DateAsset from '../../../../components/DateAsset.vue';
import type { SortEvent, LinkEvent } from '../../../../services/shop/types';

type Props = {
    modelValue: ShopDataEvent[]
}

type Emits = {
    (e: 'create:collapse', collapse: ShopDataCollapse): void,
    (e: 'create:conceptCollapse', eventIndex: number): void,
    (e: 'remove:conceptCollapse', collapseId: string, eventIndex: number): void,
    (e: 'remove:ticketFromShop', ticketId: string, collapseId?: string): void,
    (e: 'update-settings:conceptCollapse', collapse: ShopDataCollapse, eventIndex: number): void,
    (e: 'add:ticketsToShop', ticketIds: string[]): void,
    (e: 'refresh'): void,
    (e: 'move:up', value: SortEvent): void,
    (e: 'move:down', value: SortEvent): void,
    (e: 'link', value: LinkEvent): void,
    (e: 'unlink', value: LinkEvent): void,
    (e: 'open-modal:updateCollapse', collapse: ShopDataCollapse, eventIndex: number): void,
    (e: 'open-modal:addTickets', eventId: string): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

// const dialog = injectOrFail<DialogController>('dialog');
// const localization = injectOrFail<VueLocalization>('localization');

// TODO Add implementation to remove event from shop (unlink all tickets? & remove collapses)
// async function removeEventFromShop(eventId: string) {
//     const confirm = await dialog?.confirm({
//         title: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_event.title') as string || '',
//         description: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_event.description') as string || '',
//         type: 'is-danger',
//     });

//     if (confirm) {
//         console.log('@todo: remove event from shop', eventId);
//     }
// }

function createCollapseInEvent(eventId: string): void {
    const eventIndex = props.modelValue.findIndex((event) => event.guid === eventId);

    if (eventIndex !== -1) {
        emit('create:conceptCollapse', eventIndex);
    }
}

function onUpdateNestedCollapse(collapse: ShopDataCollapse) {
    if (collapse._type_ === 'collapse') {
        if (collapse.concept) {
            emit('create:collapse', collapse);
        }
    }
}

function onRemoveConceptCollapse(collapseId: string, eventIndex: number) {
    emit('remove:conceptCollapse', collapseId, eventIndex);
}

function onRemoveTicket(ticketId: string, collapseId?: string) {
    emit('remove:ticketFromShop', ticketId, collapseId);
}
</script>

<style lang="scss" scoped>
.shop-tickets__groups__event {
    &:not(:last-child) {
        border-bottom: 1px solid var(--ot-color-core-accent-secondary);
    }

    &--header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        &-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: var(--ot-spacing-default);
        }
    }
}
</style>
