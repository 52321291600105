import type { RouteConfig } from 'vue-router';
import { RouterView } from '../../services/util';
import AddonProductsListView from './views/AddonProductsListView.vue';

export function addonProductsRoutes(): RouteConfig[] {
    return [
        {
            path: 'addon-products',
            component: RouterView,
            meta: {
                title: 'dashboard.document_title.addon-products.list',
                titleFallback: 'Add-on Products',
                onContextSelect: (id: string | symbol | undefined) => {
                    if (typeof id === 'string') {
                        return {
                            name: 'addon-products.list',
                            params: { event: id },
                        };
                    }
                    return null;
                },
            },
            children: [
                {
                    path: '',
                    name: 'addon-products.list',
                    component: AddonProductsListView,
                },
            ],
        },
    ];
}
