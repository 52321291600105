<template>
    <div class="context-filter">
        <div
            v-if="opened"
            class="context-filter__backdrop"
            role="button"
            tabindex="0"
            @click="close"
            @keydown.esc="close"
        />
        <div
            v-if="opened"
            class="context-filter__card ot-card"
        >
            <OtInput
                type="search"
                :placeholder="searchLabel"
                :value="searchValue"
                class="context-filter__card__search"
                @input="handleSearch"
            />
            <span
                v-if="!filteredList.length"
                class="context-filter__card__not-found ot-text-body"
            >
                {{ notFoundLabel }}
            </span>
            <ul class="context-filter__card__list">
                <li
                    v-for="item of filteredList"
                    :key="item.guid"
                    class="context-filter__card__list__item ot-clickable"
                    :class="[
                        { selected: item.guid === selected },
                        `context-filter__card__list__item__${typeof item.guid === 'symbol' ? item.guid.description : item.guid}`,
                    ]"
                    role="button"
                    tabindex="0"
                    :title="item.name"
                    @click="selectOption(item.guid)"
                    @keydown.space="selectOption(item.guid)"
                    @keydown.enter="selectOption(item.guid)"
                >
                    <div class="context-filter__card__list__item__content">
                        <h4 class="two-line-max">
                            {{ item.name }}
                        </h4>
                        <OtIcon
                            type="carrot-right"
                            size="small"
                        />
                    </div>
                </li>
                <div
                    v-if="optionsIsPagination(options) && lastPage > 1"
                    class="context-filter__card__list__controls"
                >
                    <div
                        class="context-filter__card__list__controls__icon"
                        :disabled="currentPage < 2 || lastPage < 2"
                        role="button"
                        tabindex="0"
                        @click="shiftPage(-1)"
                        @keydown.space="shiftPage(-1)"
                        @keydown.enter="shiftPage(-1)"
                    >
                        <OtIcon
                            type="carrot-left"
                            size="small"
                        />
                    </div>

                    <span class="ot-text-small">
                        {{ currentPage }} / {{ lastPage }}
                    </span>

                    <div
                        class="context-filter__card__list__controls__icon"
                        :disabled="currentPage >= lastPage || lastPage < 2"
                        role="button"
                        tabindex="0"
                        @keydown.space="shiftPage(1)"
                        @click="shiftPage(1)"
                        @keydown.enter="shiftPage(1)"
                    >
                        <OtIcon
                            type="carrot-right"
                            size="small"
                        />
                    </div>
                </div>
            </ul>
        </div>
        <div
            v-if="back"
            class="context-filter__back"
            data-testid="context-filter-back"
        >
            <router-link
                :to="back.route"
                class="ot-button is-link is-tiny"
                :title="back.title"
            >
                <OtIcon
                    class="oti ot-button-icon--left"
                    type="carrot-left"
                    size="small"
                />
                <span class="two-line-max">{{ back.text }}</span>
            </router-link>
        </div>
        <div
            :title="activeOption?.name"
            class="context-filter__trigger ot-clickable"
            data-testid="context-filter-active-option"
            role="button"
            tabindex="0"
            @click="open"
            @keydown.space="open"
            @keydown.enter="open"
        >
            <h4 class="two-line-max">
                {{ activeOption?.name }}
            </h4>
            <OtIcon
                type="drop-both"
                size="small"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    computed, onMounted, onUnmounted, ref,
} from 'vue';
import type { UnwrapNestedRefs } from 'vue';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { useRouter } from 'vue-router/composables';
import type { CompanyRaw } from '@openticket/lib-auth';
import type {
    CompanyPath, Event, ManagementClient, Shop,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { injectOrFail } from '../../services/util';
import type { Context } from '../../services/context';
import { handleContextFilterUpdate } from '../../services/context/contextFilter';

type ContextFilterOption = {
    guid: string | symbol,
    name: string,
}

type UnwrappedPaginationEvents = UnwrapNestedRefs<Pagination<Event<ManagementClient>
    | Event<CompanyPath<ManagementClient>>> | null>;
type UnwrappedPaginationShops = UnwrapNestedRefs<Pagination<Shop<CompanyPath<ManagementClient>>> | null>;

type Props = {
    companies: CompanyRaw[],
    events: UnwrappedPaginationEvents,
    shops: UnwrappedPaginationShops,
}
const props = defineProps<Props>();

const router = useRouter();
const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');

const opened = ref<boolean>(false);
const searchValue = ref<string>('');

const currentPage = computed<number>(() => (options.value
    && optionsIsPagination(options.value) && options.value.currentPage) || 1);
const lastPage = computed<number>(() => (options.value
    && optionsIsPagination(options.value) && options.value.lastPage) || 1);

const allCompanies: symbol = Symbol.for('all_companies');

const selected = computed<string | symbol>(() => {
    if (context.isEventContext()) {
        return context.event.id;
    }
    if (context.isShopContext()) {
        return context.shop.id;
    }
    return context.company?.id || allCompanies;
});

const activeOption = computed<ContextFilterOption | undefined>((): ContextFilterOption | undefined => {
    if (context.isEventContext()) {
        return {
            guid: selected.value,
            name: context.event?.name || (typeof selected.value === 'string' ? selected.value : ''),
        };
    }
    if (context.isShopContext()) {
        return {
            guid: selected.value,
            name: context.shop?.name || (typeof selected.value === 'string' ? selected.value : ''),
        };
    }

    const active = (options.value as ContextFilterOption[]).find((option) => option.guid === selected.value);

    if (active || !context.isCompanyContext()) {
        return active;
    }

    // When the user is an admin and the company is not listed in the options, still display the context.
    return {
        name: context.company.name,
        guid: context.company.id,
    };
});

const notFoundLabel = computed<TranslateResult>(() => {
    if (context.isEventContext()) {
        return $t('dashboard.sidebar.context_filter.event.not_found');
    }
    if (context.isShopContext()) {
        return $t('dashboard.sidebar.context_filter.shop.not_found');
    }
    return $t('dashboard.sidebar.context_filter.company.not_found');
});

const searchLabel = computed<TranslateResult>(() => {
    if (context.isEventContext()) {
        return $t('dashboard.sidebar.context_filter.event.search');
    }
    if (context.isShopContext()) {
        return $t('dashboard.sidebar.context_filter.shop.search');
    }
    return $t('dashboard.sidebar.context_filter.company.search');
});

const back = computed(() => {
    if (context.isEventContext()) {
        return {
            text: context.company.name,
            title: $t('dashboard.sidebar.context_filter.back_to', { name: context.company.name }),
            route: {
                name: 'events.list',
                params: { company: context.company.id },
            },
        };
    }

    if (context.isShopContext()) {
        return {
            text: context.company.name,
            title: $t('dashboard.sidebar.context_filter.back_to', { name: context.company.name }),
            route: {
                name: 'shops.list',
                params: { company: context.company.id },
            },
        };
    }

    return null;
});

const filteredList = computed<ContextFilterOption[]>((): ContextFilterOption[] => {
    if (!options.value) {
        return [];
    }

    if (optionsIsPagination(options.value)) {
        return options.value.records.flatMap((item) => (item.id ? [ {
            guid: item.id,
            name: (item.$data.name) || '',
        } ] : []));
    }

    if (!searchValue.value) {
        return options.value;
    }

    return options.value.filter((option) => option.name.toUpperCase().includes(searchValue.value.toUpperCase()));
});

const options = computed<ContextFilterOption[] | UnwrappedPaginationShops | UnwrappedPaginationEvents>(() => {
    if (context.isEventContext()) {
        return props.events ?? [];
    }

    if (context.isShopContext()) {
        return props.shops ?? [];
    }

    return [
        {
            guid: allCompanies,
            name: String($t('dashboard.sidebar.context_filter.company.all_companies')),
        },
        ...(props.companies ?? []).map((company) => ({
            guid: String(company.guid),
            name: company.name,
        })),
    ];
});

const escListener = (event: KeyboardEvent): void => {
    if (event.key === 'Escape' || event.key === 'Esc') {
        void close();
    }
};

onMounted(() => {
    window.addEventListener('keydown', escListener);
});

onUnmounted(() => {
    window.removeEventListener('keydown', escListener);
});

function open() {
    opened.value = true;
}

function close() {
    opened.value = false;
    searchValue.value = '';
}

function selectOption(guid: string | symbol) {
    close();
    handleContextFilterUpdate(router, guid, context);
}

function optionsIsPagination(contextFilterOptions: ContextFilterOption[] | UnwrappedPaginationEvents | UnwrappedPaginationShops)
    : contextFilterOptions is UnwrappedPaginationEvents | UnwrappedPaginationShops {
    return !Array.isArray(contextFilterOptions);
}

async function handleSearch(input: string) {
    searchValue.value = input;

    if (options.value && optionsIsPagination(options.value)) {
        await options.value.setFilter('name', input);
    }
}

async function shiftPage(shift: number): Promise<void> {
    if (!options.value || !optionsIsPagination(options.value)) {
        return;
    }

    try {
        await options.value.loadPage(Math.max(1, currentPage.value + shift));
    } catch (e) {
        console.error('Shift page failed', { e });
        throw e; // TODO Proper error & logging
    }
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}
</script>

<style lang="scss" scoped>
.context-filter {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    height: 5rem;

    &__backdrop {
        z-index: 300;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: black;
        opacity: 0.3;
    }

    &__back {
        a {
            width: 100%;
            justify-content: flex-start;

            svg {
                min-width: 1rem;
            }
        }
    }

    &__trigger {
        display: flex;
        justify-content: space-between;
        align-items: center;

        h4 {
            margin: 0;
        }

        svg {
            min-width: 1rem;
        }
    }

    &__card {
        z-index: 301;
        position: absolute;
        width: calc(100% + var(--ot-spacing-3xl));
        border-radius: var(--ot-spacing-sm);
        display: flex;
        flex-direction: column;
        gap: var(--ot-spacing-sm);
        padding: var(--ot-spacing-sm) var(--ot-spacing-default);
        margin: 0 calc(var(--ot-spacing-unit) * -1.25);

        @media (min-width: 40rem) and (max-width: 60rem) {
            --ot-spacing-sm: calc(1rem * 0.75);
        }

        &__not-found {
            text-align: center;
            color: var(--ot-color-core-light-accent-primary);
        }

        &__list {
            max-height: 50vh;
            overflow-x: hidden;
            overflow-y: auto;
            margin: 0 calc(-1 * var(--ot-spacing-unit));

            &__item {
                padding: 0 var(--ot-spacing-lg);

                &__content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: var(--ot-spacing-xs) 0;
                    border-top: 1px solid var(--ot-color-core-accent-tertiary);

                    h4 {
                        margin: calc(-1 * var(--ot-spacing-3xs)) 0 var(--ot-spacing-3xs) 0;
                    }

                    svg {
                        min-width: var(--ot-spacing-default);
                    }
                }

                &.selected {
                    background: var(--ot-color-core-light-accent-secondary);
                    color: var(--ot-color-core-brand);
                }

                &.selected &__content {
                    border-top-color: #00000000;
                }

                &.selected + & {
                    & > div {
                        border-top-color: #00000000;
                    }
                }

                &:first-child &__content {
                    border-top-color: #00000000;
                }
            }

            &__controls {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: var(--ot-spacing-xs);
                border-top: 1px solid var(--ot-color-core-accent-tertiary);
                padding-top: var(--ot-spacing-sm);

                &__icon {
                    display: flex;
                    align-items: center;
                    cursor: pointer;

                    &[disabled], &.disabled {
                        color: var(--ot-color-core-accent-primary);
                        pointer-events: none;
                    }
                }
            }
        }
    }
}

.two-line-max {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @supports(-webkit-line-clamp: 2) {
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}
</style>
