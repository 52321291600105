<template>
    <FormModal
        v-if="modal.isOpen"
        data-testid="shop-create-modal"
        :form="shopForm"
        :modal="modal"
        :title="$t('dashboard.shops.new.title')"
        :subtitle="$t('dashboard.shops.new.subtitle')"
        :submit-button-labels="{
            text: $t('dashboard.shops.list.new.create'),
            title: $t('dashboard.shops.list.new.title')
        }"
    >
        <ShopCreateForm :form="shopForm" />
    </FormModal>
</template>

<script setup lang="ts">
import { reactive, type UnwrapNestedRefs } from 'vue';
import type { CompanyPath, ManagementClient, Shop } from '@openticket/lib-management';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';
import FormModal from '../../../components/form/FormModal.vue';
import { useCreateForm, type FormModalComposableResult } from '../../../composables/forms';
import ShopCreateForm from './forms/ShopCreateForm.vue';

type Props = {
    modal: UnwrapNestedRefs<FormModalComposableResult>,
}

type Emits = {
    (e: 'saved', guid: string | null): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const context = injectOrFail<Context>('context');

if (!context.isCompanyContext()) {
    // TODO: Proper error handling and logging
    throw Error('Invalid context');
}

const shopForm = reactive(
    useCreateForm<Shop<CompanyPath<ManagementClient>>, CompanyPath<ManagementClient>>(
        context.company.model.shops.new(),
        context.company.model.shops,
        {
            async onSubmit(model) {
                emit('saved', model.id);
                await Promise.resolve();
            },
        },
    ),
);
</script>
