<template>
    <div
        v-if="settings"
        class="shop-settings__style"
    >
        <h2 class="has-margin">
            {{ $t('dashboard.shop.settings.style.template.title') }}
        </h2>
        <h5 class="ot-text-tiny has-margin subtitle">
            {{ $t('dashboard.shop.settings.style.description') }}
        </h5>

        <OtInputField
            key="shop_settings-brand_color"
            :label="$t('dashboard.shop.settings.style.brand_color.label')"
        >
            <OtInput
                v-model="settings.static.style.color.core.brand"
                type="color"
                :placeholder="$t('dashboard.shop.settings.style.brand_color.placeholder')"
            />

            <ColorConformationLevels
                v-if="settings.static.style.color.core.brand"
                class="shop-settings__style__color-conformation-levels"
                :target="settings.static.style.color.core.brand"
                :target-name="$t('dashboard.shop.settings.style.brand_color.label')"
            >
                <ColorConformationLevelContrast
                    v-for="relation in brandRelations"
                    :id="relation.id"
                    :key="relation.id"
                    :related="relation.value"
                    :related-name="$t(relation.name)"
                    :related-location="relation.location"
                />
            </ColorConformationLevels>
        </OtInputField>

        <OtInputField
            key="shop_settings-theme"
            :label="$t('dashboard.shop.settings.style.theme.label')"
        >
            <OtInput
                v-model="settings.static.theme"
                type="select"
                :options="themeOptions"
            />
        </OtInputField>

        <h2 class="has-margin">
            {{ $t('dashboard.shop.settings.style.canvas.title') }}
        </h2>

        <OtInputField
            key="shop_settings-canvas-background"
            :label="$t('dashboard.shop.settings.style.canvas.background.label')"
        >
            <OtInput
                v-model="settings.static.style.shop.background"
                type="color"
                :placeholder="$t('dashboard.shop.settings.style.canvas.background.placeholder')"
            />

            <ColorConformationLevels
                v-if="settings.static.style.shop.background"
                class="shop-settings__style__color-conformation-levels"
                :target="settings.static.style.shop.background"
                :target-name="$t('dashboard.shop.settings.style.canvas.background.label')"
            >
                <ColorConformationLevelContrast
                    v-for="relation in canvasBackgroundRelations"
                    :id="relation.id"
                    :key="relation.id"
                    :related="relation.value"
                    :related-name="$t(relation.name)"
                    :related-location="relation.location"
                />
            </ColorConformationLevels>
        </OtInputField>

        <OtInputField
            key="shop_settings-canvas-text"
            :label="$t('dashboard.shop.settings.style.canvas.text.label')"
        >
            <OtInput
                v-model="settings.static.style.shop.color"
                type="color"
                :placeholder="$t('dashboard.shop.settings.style.canvas.text.placeholder')"
            />

            <ColorConformationLevels
                v-if="settings.static.style.shop.color"
                class="shop-settings__style__color-conformation-levels"
                :target="settings.static.style.shop.color"
                :target-name="$t('dashboard.shop.settings.style.canvas.text.label')"
            >
                <ColorConformationLevelContrast
                    v-for="relation in canvasColorRelations"
                    :id="relation.id"
                    :key="relation.id"
                    :related="relation.value"
                    :related-name="$t(relation.name)"
                    :related-location="relation.location"
                />
            </ColorConformationLevels>
        </OtInputField>

        <h2 class="has-margin">
            {{ $t('dashboard.shop.settings.style.card.title') }}
        </h2>

        <OtInputField
            key="shop_settings-card-background"
            :label="$t('dashboard.shop.settings.style.card.background.label')"
        >
            <OtInput
                v-model="settings.static.style.card.background"
                type="color"
                :placeholder="$t('dashboard.shop.settings.style.card.background.placeholder')"
            />

            <ColorConformationLevels
                v-if="settings.static.style.card.background"
                class="shop-settings__style__color-conformation-levels"
                :target="settings.static.style.card.background"
                :target-name="$t('dashboard.shop.settings.style.card.background.label')"
            >
                <ColorConformationLevelContrast
                    v-for="relation in cardBackgroundRelations"
                    :id="relation.id"
                    :key="relation.id"
                    :related="relation.value"
                    :related-name="$t(relation.name)"
                    :related-location="relation.location"
                />
            </ColorConformationLevels>
        </OtInputField>

        <OtInputField
            key="shop_settings-card-text"
            :label="$t('dashboard.shop.settings.style.card.text.label')"
        >
            <OtInput
                v-model="settings.static.style.card.color"
                type="color"
                :placeholder="$t('dashboard.shop.settings.style.card.text.placeholder')"
            />

            <ColorConformationLevels
                v-if="settings.static.style.card.color"
                class="shop-settings__style__color-conformation-levels"
                :target="settings.static.style.card.color"
                :target-name="$t('dashboard.shop.settings.style.card.text.label')"
            >
                <ColorConformationLevelContrast
                    v-for="relation in cardColorRelations"
                    :id="relation.id"
                    :key="relation.id"
                    :related="relation.value"
                    :related-name="$t(relation.name)"
                    :related-location="relation.location"
                />
            </ColorConformationLevels>
        </OtInputField>

        <div style="flex: 1" />

        <OtInputField
            v-if="!settings.isStaticDefault"
            :label="$t('dashboard.common.action.reset.label')"
            :description="$t('dashboard.shop.settings.reset.action.description')"
        >
            <button
                class="ot-button is-danger is-small"
                type="button"
                :title="$t('dashboard.common.action.reset.title')"
                @click="resetShopSettings()"
            >
                <OtIcon
                    class="ot-button-icon--left"
                    size="small"
                    type="warning"
                />
                {{ $t('dashboard.common.action.reset.text') }}
            </button>
        </OtInputField>
    </div>
</template>

<script setup lang="ts">
import type { CustomShopSettingsClient } from '@openticket/lib-custom-shop-settings';
import type { DialogController, OtPreview } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import type VueNotifications from '@openticket/vue-notifications';
import {
    computed, inject, ref, type Ref,
} from 'vue';
import type { ColorRelation } from '../../../../components/color';
import {
    ColorConformationLevels,
    ColorConformationLevelContrast,
} from '../../../../components/color';
import { injectOrFail } from '../../../../services/util';
import type { Context } from '../../../../services/context';

interface DefaultColors {
    white: string;
    dark_canvas_background: string;
    light_canvas_background: string;
    dark_canvas_color: string;
    light_canvas_color: string;
    dark_card_background: string;
    light_card_background: string;
    dark_card_color: string;
    light_card_color: string;
}

type Emits = {
    (e: 'reset'): void
}

const emit = defineEmits<Emits>();

const settings = injectOrFail<CustomShopSettingsClient>('settings');
const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');
const notifications = injectOrFail<VueNotifications>('notifications');
const context = injectOrFail<Context>('context');

const previewRef = inject<Ref<InstanceType<typeof OtPreview> | null>>('previewRef');

const elReactive = ref<Element | null>(null);

const defaultColors = computed<DefaultColors>(() => {
    if (!elReactive.value) {
        return {
            white: '#ffffff',
            dark_canvas_background: '',
            light_canvas_background: '',
            dark_canvas_color: '',
            light_canvas_color: '',
            dark_card_background: '',
            light_card_background: '',
            dark_card_color: '',
            light_card_color: '',
        };
    }

    const computedStyle: CSSStyleDeclaration = window.getComputedStyle(elReactive.value);

    return {
        white: computedStyle.getPropertyValue('--ot-color-core-white'),
        dark_canvas_background: computedStyle.getPropertyValue('--ot-color-core-dark-background-secondary'),
        light_canvas_background: computedStyle.getPropertyValue('--ot-color-core-light-background-secondary'),
        dark_canvas_color: computedStyle.getPropertyValue('--ot-color-core-dark-foreground-primary'),
        light_canvas_color: computedStyle.getPropertyValue('--ot-color-core-light-foreground-primary'),
        dark_card_background: computedStyle.getPropertyValue('--ot-color-core-dark-background-primary'),
        light_card_background: computedStyle.getPropertyValue('--ot-color-core-light-background-primary'),
        dark_card_color: computedStyle.getPropertyValue('--ot-color-core-dark-foreground-primary'),
        light_card_color: computedStyle.getPropertyValue('--ot-color-core-light-foreground-primary'),
    };
});

const dynamicDisabled = computed<boolean>(() => [
    ((settings.static.style.color || {}).core || {}).brand || null,
    (settings.static.style.shop || {}).background || null,
    (settings.static.style.shop || {}).color || null,
    (settings.static.style.card || {}).background || null,
    (settings.static.style.card || {}).color || null,
].some((setting) => setting !== null));

const themeOptions = computed<Record<string, string>>(() => {
    const options: { [key: string]: string } = {
        light: localization.getI18n().t('dashboard.shop.settings.style.theme.option.light') as string,
        dark: localization.getI18n().t('dashboard.shop.settings.style.theme.option.dark') as string,
    };

    if (!dynamicDisabled.value) {
        options.dynamic = localization.getI18n().t('dashboard.shop.settings.style.theme.option.dynamic') as string;
    }

    return options;
});

const themeIsDarkOrDynamic = computed<boolean>(() => {
    if ([ 'dynamic', 'dark' ].includes(settings.static.theme)) {
        return true;
    }

    return !settings.static.theme;
});

const themeIsLightOrDynamic = computed<boolean>(() => {
    if ([ 'dynamic', 'light' ].includes(settings.static.theme)) {
        return true;
    }

    return !settings.static.theme;
});

const brandRelations = computed<ColorRelation[]>(() => {
    const shopBackground: string | null = (settings.static.style.shop ? settings.static.style.shop.background : null) || null;
    const cardBackground: string | null = (settings.static.style.card ? settings.static.style.card.background : null) || null;

    const white: ColorRelation = {
        id: 'brand-vs-white',
        value: defaultColors.value.white,
        name: 'dashboard.shop.settings.style.brand_color.vs.white',
        location: 'above',
    };

    const overwrittenCanvasBackground: ColorRelation = {
        id: 'brand-vs-overwritten_canvas_background',
        value: shopBackground,
        name: 'dashboard.shop.settings.style.brand_color.vs.canvas_background',
        location: 'below',
    };
    const overwrittenCardBackground: ColorRelation = {
        id: 'brand-vs-overwritten_card_background',
        value: cardBackground,
        name: 'dashboard.shop.settings.style.brand_color.vs.card_background',
        location: 'below',
    };

    const darkCanvasBackground: ColorRelation = {
        id: 'brand-vs-dark_canvas_background',
        value: defaultColors.value.dark_canvas_background,
        name: 'dashboard.shop.settings.style.brand_color.vs.default_dark_canvas_background',
        location: 'below',
    };
    const darkCardBackground: ColorRelation = {
        id: 'brand-vs-dark_card_background',
        value: defaultColors.value.dark_card_background,
        name: 'dashboard.shop.settings.style.brand_color.vs.default_dark_card_background',
        location: 'below',
    };

    const lightCanvasBackground: ColorRelation = {
        id: 'brand-vs-light_canvas_background',
        value: defaultColors.value.light_canvas_background,
        name: 'dashboard.shop.settings.style.brand_color.vs.default_light_canvas_background',
        location: 'below',
    };
    const lightCardBackground: ColorRelation = {
        id: 'brand-vs-light_card_background',
        value: defaultColors.value.light_card_background,
        name: 'dashboard.shop.settings.style.brand_color.vs.default_light_card_background',
        location: 'below',
    };

    return [
        white,
        ...(shopBackground ? [ overwrittenCanvasBackground ] : [
            ...(themeIsDarkOrDynamic.value ? [ darkCanvasBackground ] : []),
            ...(themeIsLightOrDynamic.value ? [ lightCanvasBackground ] : []),
        ]),
        ...(cardBackground ? [ overwrittenCardBackground ] : [
            ...(themeIsDarkOrDynamic.value ? [ darkCardBackground ] : []),
            ...(themeIsLightOrDynamic.value ? [ lightCardBackground ] : []),
        ]),
    ];
});

const canvasBackgroundRelations = computed<ColorRelation[]>(() => {
    const brandColor: string | null = ((settings.static.style.color || {}).core || {}).brand || null;
    const canvasColor: string | null = (settings.static.style.shop || {}).color || null;

    const brand: ColorRelation = {
        id: 'canvas_background-vs-brand',
        value: brandColor,
        name: 'dashboard.shop.settings.style.canvas.background.vs.brand',
        location: 'above',
    };

    const overwrittenCanvasColor: ColorRelation = {
        id: 'canvas_background-vs-canvas_color',
        value: canvasColor,
        name: 'dashboard.shop.settings.style.canvas.background.vs.canvas_color',
        location: 'above',
    };
    const darkCanvasColor: ColorRelation = {
        id: 'canvas_background-vs-default_dark_canvas_color',
        value: defaultColors.value.dark_canvas_color,
        name: 'dashboard.shop.settings.style.canvas.background.vs.default_dark_canvas_color',
        location: 'above',
    };
    const lightCanvasColor: ColorRelation = {
        id: 'canvas_background-vs-default_light_canvas_color',
        value: defaultColors.value.light_canvas_color,
        name: 'dashboard.shop.settings.style.canvas.background.vs.default_light_canvas_color',
        location: 'above',
    };

    return [
        ...(brandColor ? [ brand ] : []),

        ...(canvasColor ? [ overwrittenCanvasColor ] : [
            ...(themeIsDarkOrDynamic.value ? [ darkCanvasColor ] : []),
            ...(themeIsLightOrDynamic.value ? [ lightCanvasColor ] : []),
        ]),
    ];
});

const canvasColorRelations = computed<ColorRelation[]>(() => {
    const canvasBackground: string | null = (settings.static.style.shop || {}).background || null;

    const overwrittenCanvasBackground: ColorRelation = {
        id: 'canvas_color-vs-canvas_background',
        value: canvasBackground,
        name: 'dashboard.shop.settings.style.canvas.text.vs.canvas_background',
        location: 'below',
    };
    const darkCanvasBackground: ColorRelation = {
        id: 'canvas_color-vs-default_dark_canvas_background',
        value: defaultColors.value.dark_canvas_background,
        name: 'dashboard.shop.settings.style.canvas.text.vs.default_dark_canvas_background',
        location: 'below',
    };
    const lightCanvasBackground: ColorRelation = {
        id: 'canvas_color-vs-default_light_canvas_background',
        value: defaultColors.value.light_canvas_background,
        name: 'dashboard.shop.settings.style.canvas.text.vs.default_light_canvas_background',
        location: 'below',
    };

    return canvasBackground ? [ overwrittenCanvasBackground ] : [
        ...(themeIsDarkOrDynamic.value ? [ darkCanvasBackground ] : []),
        ...(themeIsLightOrDynamic.value ? [ lightCanvasBackground ] : []),
    ];
});

const cardBackgroundRelations = computed<ColorRelation[]>(() => {
    const brandColor: string | null = ((settings.static.style.color || {}).core || {}).brand || null;

    const cardColor: string | null = (settings.static.style.card || {}).color || null;

    const brand: ColorRelation = {
        id: 'card_background-vs-brand',
        value: brandColor,
        name: 'dashboard.shop.settings.style.card.background.vs.brand',
        location: 'above',
    };

    const overwrittenCardColor: ColorRelation = {
        id: 'card_background-vs-card_color',
        value: cardColor,
        name: 'dashboard.shop.settings.style.card.background.vs.card_color',
        location: 'above',
    };
    const darkCardColor: ColorRelation = {
        id: 'card_background-vs-default_dark_card_color',
        value: defaultColors.value.dark_card_color,
        name: 'dashboard.shop.settings.style.card.background.vs.default_dark_card_color',
        location: 'above',
    };
    const lightCardColor: ColorRelation = {
        id: 'card_background-vs-default_light_card_color',
        value: defaultColors.value.light_card_color,
        name: 'dashboard.shop.settings.style.card.background.vs.default_light_card_color',
        location: 'above',
    };

    return [
        ...(brandColor ? [ brand ] : []),

        ...(cardColor ? [ overwrittenCardColor ] : [
            ...(themeIsDarkOrDynamic.value ? [ darkCardColor ] : []),
            ...(themeIsLightOrDynamic.value ? [ lightCardColor ] : []),
        ]),
    ];
});

const cardColorRelations = computed<ColorRelation[]>(() => {
    const cardBackground: string | null = (settings.static.style.card || {}).background || null;

    const overwrittenCardBackground: ColorRelation = {
        id: 'card_color-vs-card_background',
        value: cardBackground,
        name: 'dashboard.shop.settings.style.card.text.vs.card_background',
        location: 'below',
    };
    const darkCardBackground: ColorRelation = {
        id: 'card_color-vs-default_dark_card_background',
        value: defaultColors.value.dark_card_background,
        name: 'dashboard.shop.settings.style.card.text.vs.default_dark_card_background',
        location: 'below',
    };
    const lightCardBackground: ColorRelation = {
        id: 'card_color-vs-default_light_card_background',
        value: defaultColors.value.light_card_background,
        name: 'dashboard.shop.settings.style.card.text.vs.default_light_card_background',
        location: 'below',
    };

    return cardBackground ? [ overwrittenCardBackground ] : [
        ...(themeIsDarkOrDynamic.value ? [ darkCardBackground ] : []),
        ...(themeIsLightOrDynamic.value ? [ lightCardBackground ] : []),
    ];
});

async function resetShopSettings(): Promise<void> {
    const confirm = await dialog?.confirm({
        title: localization.getI18n().t('dashboard.common.confirm.permanent.title') as string,
        description: localization?.getI18n().t('dashboard.common.confirm.permanent.description') as string,
        type: 'is-danger',
    });

    if (confirm) {
        if (context.isShopContext()) {
            try {
                settings.setCompanyScope(context.company.id);
                await settings.resetStaticSettings();

                emit('reset');

                // eslint-disable-next-line @typescript-eslint/no-unsafe-call
                previewRef?.value?.reloadContent();

                notifications.success(localization.getI18n().t('dashboard.shop.settings.reset.notification.success'));
            } catch (e) {
                notifications.warning(localization.getI18n().t('dashboard.shop.settings.reset.notification.fail'));

                throw e;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.shop-settings__style {
    &__color-conformation-levels {
        margin-top: var(--ot-spacing-default);
    }

    .subtitle {
        color: var(--ot-color-core-foreground-secondary);
    }
}
</style>
