<template>
    <div class="shop-tickets__collapse ot-my[xs]">
        <div class="shop-tickets__collapse--header ot-p[md]">
            <h3>
                <OtIcon
                    v-if="collapse.concept"
                    type="file"
                    class=""
                    size="small"
                />
                {{ collapse.title }}
                <button
                    class="ot-button is-tiny ot-ml[sm] is-outline"
                    @click="emit('open-edit:collapse', collapse)"
                >
                    <OtIcon
                        type="edit"
                        class="ot-mr[xs]"
                        size="tiny"
                    />
                    {{ $t('dashboard.shop_tickets.edit.text' ) }}
                </button>
            </h3>
            <button
                v-if="!collapse.concept"
                data-drag-handle
                class="ot-button is-tiny is-text ot-px[sm] drop-both"
            >
                <OtIcon
                    type="drop-both"
                    size="small"
                />
            </button>
            <button
                v-if="collapse.concept"
                class="ot-button is-tiny is-text ot-px[sm] close"
                @click="removeCollapseFromShop(collapse)"
            >
                <OtIcon
                    type="close"
                    size="small"
                />
            </button>
        </div>

        <draggable
            v-if="tickets"
            :list="tickets"
            handle="[data-drag-handle]"
            :group="draggableGroupKey"
            class="shop-tickets__collapse--content ot-px[lg] ot-pb[md]"
            @add="onAdd"
            @remove="onRemove"
            @sort="onSort"
        >
            <DraggableTicket
                v-for="ticket in tickets"
                :key="ticket.guid"
                :ticket="ticket"
                @remove="emit('remove:ticketFromShop', ticket.guid, collapse.guid)"
            />
            <template #footer>
                <div class="shop-tickets__collapse--footer ot-mt[md]">
                    {{ $t('dashboard.shop_tickets.drag_tickets.text') }}
                    <!-- TODO Add functionality to button -->
                    <!-- <button class="ot-button is-tiny is-outline" disabled>
                        <OtIcon type="plus" class="ot-button-icon--left" size="tiny" />
                        {{ $t('dashboard.shop_tickets.add_tickets.text') }}
                    </button> -->
                </div>
            </template>
        </draggable>
    </div>
</template>

<script setup lang="ts">
import draggable from 'vuedraggable';
import type { DialogController } from '@openticket/vue-dashboard-components';
import type { VueLocalization } from '@openticket/vue-localization';
import { reactive, watch } from 'vue';
import DraggableTicket from './DraggableTicket.vue';
import { injectOrFail } from '../../../../services/util';
import type { ShopDataCollapse, ShopDataTicket } from '../../../../services/shop';
import useDraggableInterpreter from '../../composables/useDraggableInterpreter';
import type { SortEvent, LinkEvent } from '../../../../services/shop/types';

const dialog = injectOrFail<DialogController>('dialog');
const localization = injectOrFail<VueLocalization>('localization');

type Props = {
    collapse: ShopDataCollapse,
    draggableGroupKey: string
}

type Emits = {
    (e: 'update:collapse', value: ShopDataCollapse): void,
    (e: 'remove:conceptCollapse', collapseId: string): void,
    (e: 'remove:ticketFromShop', ticketId: string, collapseId: string): void,
    (e: 'open-edit:collapse', collapse: ShopDataCollapse): void,
    (e: 'move:up', value: SortEvent): void,
    (e: 'move:down', value: SortEvent): void,
    (e: 'link', value: LinkEvent): void,
    (e: 'unlink', value: LinkEvent): void,
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

// DON'T Change this to ref because it breaks the reactivity of the drag and drop system.
// Ignore warning in the console, this warning will dissapear when migrated to vue3.
const tickets = reactive<ShopDataTicket[]>(props.collapse.items);

watch(tickets, () => {
    emit('update:collapse', {
        ...props.collapse,
        ...{
            items: tickets,
        },
    });
});

async function removeCollapseFromShop(collapse: ShopDataCollapse) {
    const confirm = await dialog?.confirm({
        title: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_collapse.title') as string || '',
        description: localization?.getI18n().t('dashboard.shop_tickets.confirm.remove_collapse.description') as string || '',
        type: 'is-danger',
    });

    if (confirm) {
        if (collapse.concept) {
            emit('remove:conceptCollapse', collapse.guid);
        } else {
            // TODO Implement removing existing collapses
        }
    }
}

const {
    onSort, onAdd, onRemove,
} = useDraggableInterpreter(tickets, emit, props.collapse);
</script>

<style lang="scss" scoped>
.shop-tickets__collapse {
    border-radius: var(--ot-input-radius);
    border: 1px solid var(--ot-color-core-accent-secondary);
    overflow: hidden;
    background-color: var(--ot-color-core-light-background-secondary);

    &--header {
        background-color: var(--ot-color-core-white);
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .close:hover {
            color: var(--ot-color-accent-orange-dark);
        }

        .drop-both:hover {
            color: var(--ot-color-core-brand);
        }
    }

    &--footer {
        color: var(--ot-color-core-accent-primary)
    }

    // hack
    &--footer + :deep(.sortable-ghost) {
        display: none;
    }
}
</style>
