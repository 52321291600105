<template>
    <div class="fancy-radio">
        <div
            v-for="option in options"
            :key="option.value"
            class="fancy-radio__item ot-clickable"
            :class="{checked: value === option.value}"
            role="radio"
            tabIndex="0"
            :aria-checked="value === option.value"
            @click="updateValue(option.value)"
            @keydown.enter="updateValue(option.value)"
            @keydown.space="updateValue(option.value)"
        >
            <div class="fancy-radio__item__label ot-radio">
                <input
                    :id="`radio_${id}_${option.value}`"
                    type="radio"
                    :name="`radio_${id}`"
                    :value="option.value"
                    :checked="value === option.value"
                >
                <label :for="`radio_${id}_${option.value}`"><h3>{{ option.label }}</h3></label>
            </div>
            <slot :name="option.value" />
            <span
                v-if="option.description"
                class="ot-text-body"
            >{{ option.description }}</span>
        </div>
    </div>
</template>

<script lang="ts" setup>
import type { TranslateResult } from '@openticket/vue-localization';

type Option = {
    value: string,
    label: string | TranslateResult,
    description?: string | TranslateResult,
}

type Props = {
    initialChecked?: string,
    options: Option[],
    value: string,
}

type Emits = {
    (e: 'checked', value: string): void,
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const id = Math.random().toString(36).substring(7);

function updateValue(value: string) {
    emit('checked', value);
}
</script>

<style lang="scss" scoped>
@import "../assets/scss/mixins.scss";

.fancy-radio {
    display: flex;
    flex-direction: row;

    @include breakpoint(mob) {
        flex-direction: column;
    }

    gap: var(--ot-spacing-xs);

    &__item {
        padding: var(--ot-spacing-lg);
        border: 2px solid var(--ot-color-core-accent-tertiary);
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        flex: 1;

        &__label {
            h3 {
                margin-top: -0.375rem;
                color: var(--ot-color-core-foreground-primary);
            }

            display: flex;
            align-items: center;
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }

        &.checked {
            border-color: var(--ot-color-core-brand);

            &:active {
                opacity: 1;
            }
        }
    }
}
</style>
