import type { VueConstructor } from 'vue';
import { isOtError, send } from '@openticket/lib-log';
import type { PluginsManager } from './manager';
import { Plugin } from './plugin';
import type { FeatureFlag } from '../services/featureFlag';
import { Posthog } from '../services/featureFlag';

export class FeatureFlagPlugin extends Plugin<FeatureFlag> {

    async install(plugins: PluginsManager, Vue: VueConstructor): Promise<void> {
        const featureFlagService = new Posthog();

        const whitelabel = await plugins.whitelabel.loading;

        const url = plugins.options.featureFlag?.url || whitelabel.dashboard.feature_flag_url;
        const apiKey = import.meta.env.VITE_API_KEY_FEATURE_FLAG;

        try {
            if (url && apiKey) {
                await featureFlagService.init(url, apiKey);
            }
        } catch (e: unknown) {
            if (e instanceof Error) {
                this.errors.push(e.toString());

                if (isOtError(e)) {
                    send(e);
                }
            }
        } finally {
            Vue.mixin({ provide: { featureFlag: featureFlagService } });

            this.resolve(featureFlagService);
        }
    }

}
