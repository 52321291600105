<template>
    <div class="fix-reset-password">
        <ModalHeader hide-padding />

        <div class="fix-reset-password__header">
            <h1>{{ $t('dashboard.reset_password.title') }}</h1>
            <div class="ot-label">
                <i18n path="dashboard.reset_password.subtitle.text">
                    <template #contact>
                        <a
                            v-if="$whitelabel.dashboard.support_url"
                            class="ot-link ot-clickable"
                            target="_blank"
                            :href="$whitelabel.dashboard.support_url"
                        >
                            {{ $t('dashboard.reset_password.subtitle.contact') }}
                        </a>
                        <span v-else>
                            {{ $t('dashboard.reset_password.subtitle.contact') }}
                        </span>
                    </template>
                    <template #entity>
                        <span class="terms-entity terms-entity-first">
                            {{ $whitelabel.name }}
                        </span>
                    </template>
                </i18n>
            </div>
            <h5
                v-if="error.incorrectPassword"
                class="warning"
            >
                {{ $t(error.incorrectPassword, validationRules.new_password) }}
            </h5>
        </div>
        <form @submit.prevent="submitForm($event)">
            <div class="fix-reset-password__content">
                <InputField
                    :label="$t('dashboard.reset_password.form.create_password')"
                    :required="rules.new_password && rules.new_password.includes('required')"
                    :optional="rules.new_password && rules.new_password.includes('optional')"
                >
                    <OtInput
                        v-model="resetPasswordData.new_password"
                        data-testid="password-field"
                        type="password"
                        :invalid="!!error.incorrectPassword"
                    />
                </InputField>
                <InputField :label="$t('dashboard.reset_password.form.confirm_password')">
                    <OtInput
                        v-model="resetPasswordData.new_password_confirmation"
                        data-testid="password-confirmation-field"
                        type="password"
                    />
                </InputField>
            </div>
            <div class="fix-reset-password__footer">
                <button
                    class="ot-button is-large"
                    type="submit"
                    :disabled="formNotCompleted"
                    :title="$t('dashboard.reset_password.form.button.title')"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="check"
                    />
                    {{ $t('dashboard.reset_password.form.button.text') }}
                </button>
            </div>
        </form>
    </div>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import type { TFAError } from '@openticket/vue-tfa-confirmation';
import type { AuthClient } from '@openticket/lib-auth';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import urlJoin from 'url-join';
import { OtInput } from '@openticket/vue-input';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import InputField from '../../../components/form/InputField.vue';
import { injectOrFail } from '../../../services/util';

interface ResetPasswordData {
    new_password: string | null;
    new_password_confirmation: string | null;
}

type PasswordRules = {
    new_password: string[]
}

type ValidationRules = {
    new_password: {[key: string]: string | TranslateResult}
}

type Props = {
    error: TFAError
}

type Emits = {
    (e: 'submit', new_password: string, new_password_confirmation: string): void
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const auth = injectOrFail<AuthClient>('auth');
const localization = injectOrFail<VueLocalization>('localization');
const resetPasswordData = reactive<ResetPasswordData>({
    new_password: null,
    new_password_confirmation: null,
});
const rules = ref<PasswordRules>({ new_password: [] });
const validationRules = reactive<ValidationRules>({
    new_password: { attribute: $t('dashboard.invitation.complete_profile.form.attributes.password') },
});

const formCompleted = computed((): boolean => !!resetPasswordData.new_password && !!resetPasswordData.new_password_confirmation);
const formNotCompleted = computed((): boolean => !formCompleted.value);

void (async (): Promise<void> => {
    if (!auth) {
        return;
    }

    // TODO move to a form and remove fetching rules locally
    rules.value = (await auth.$http.get<PasswordRules>(urlJoin(auth.$path, 'passwords', 'reset', 'token', 'rules'))).data;

    rules.value.new_password.forEach((rule) => {
        const split = rule.split(':');
        if (split.length >= 2) {
            [ , validationRules.new_password[split[0]] ] = split;
        }
    });
})();

function submitForm(e: Event): void {
    e.preventDefault();

    if (formCompleted.value) {
        submit();
    }
}

function submit(): void {
    emit(
        'submit',
        resetPasswordData.new_password as string,
        resetPasswordData.new_password_confirmation as string,
    );
}

// TODO: Remove when moving to Vue 3
function $t(value: string, attr?: Record<string, string>) {
    return localization.getI18n().t(value, attr);
}
</script>

<style lang="scss" scoped>
.fix-reset-password {
    &__header {
        margin-bottom: var(--ot-spacing-lg);

        .warning {
            color: var(--ot-color-accent-orange-dark);
        }

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }
    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);
    }

    &__footer .ot-button {
        width: 100%;
    }
}
</style>
