<template>
    <OtDialogModal
        ref="modalRef"
        :width="540"
    >
        <ErrorView
            v-if="error"
            :label="error.message"
        />
        <OtCard class="confirm-with-yes-modal">
            <OtCardHeader
                :title="title"
                :subtitle="subtitle"
            />
            <OtCardContent>
                <div class="confirm-with-yes-modal__tile-header">
                    <slot name="description">
                        {{ description }}
                    </slot>
                    {{ $t('dashboard.company.edit.actions.confirm.for_sure') }}
                </div>
                <div class="confirm-with-yes-modal__tile-explainer">
                    {{ $t('dashboard.company.edit.actions.confirm.help_text') }}
                </div>
                <InputField>
                    <OtInput
                        v-model="confirmInput"
                        :placeholder="$t('dashboard.company.edit.actions.confirm.keyword')"
                        class="input"
                        data-testid="confirm-with-yes-modal-input"
                        type="text"
                    />
                </InputField>
            </OtCardContent>
            <OtCardFooter>
                <template #left>
                    <button
                        class="ot-button is-dark"
                        :title="$t('dashboard.common.action.cancel.title')"
                        @click="close"
                    >
                        <OtIcon
                            type="close"
                            class="ot-button-icon--left"
                        />
                        {{ $t('dashboard.common.action.cancel.text') }}
                    </button>
                </template>
                <template #right>
                    <button
                        :disabled="!ready"
                        class="ot-button"
                        data-testid="confirm-with-yes-modal-confirm"
                        @click="confirm"
                    >
                        <OtIcon
                            type="check"
                            class="ot-button-icon--left"
                        />
                        {{ confirmLabel }}
                    </button>
                </template>
            </OtCardFooter>
        </OtCard>
    </OtDialogModal>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { OtDialogModal } from '@openticket/vue-dashboard-components';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { injectOrFail } from '../../../../../services/util';
import InputField from '../../../../../components/form/InputField.vue';
import { useGenericErrorHandling } from '../../../../../composables';

const { error, handleError } = useGenericErrorHandling();
const localization = injectOrFail<VueLocalization>('localization');
const CONFIRM_STRING = localization.getI18n().t('dashboard.company.edit.actions.confirm.keyword') as string;

type Props = {
    confirmLabel: TranslateResult,
    description?: TranslateResult,
    subtitle?: TranslateResult,
    title: TranslateResult,
}

defineProps<Props>();

type Emits = {
    (e: 'confirm'): void,
}

const emit = defineEmits<Emits>();

const modalRef = ref<InstanceType<typeof OtDialogModal> | null>(null);
const confirmInput = ref<string | null>(null);
const ready = computed<boolean>(() => !!confirmInput.value && confirmInput.value.toUpperCase() === CONFIRM_STRING.toUpperCase());

function confirm() {
    emit('confirm');
    close();
}

function close() {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    modalRef.value?.close();
}

function open() {
    confirmInput.value = null;

    try {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        modalRef.value?.show();
    } catch (e) {
        void handleError(e);
    }
}

defineExpose({
    open,
});
</script>

<!-- None-scoped to allow slot content to use this style -->
<style lang="scss">
.confirm-with-yes-modal__highlighted {
    font-weight: 500;
}
</style>

<style lang="scss" scoped>
.confirm-with-yes-modal {
    color: var(--ot-color-core-light-foreground-secondary);

    &__tile-header {
        padding-bottom: var(--ot-spacing-unit);
    }

    &__tile-explainer {
        font-size: 1rem !important;
        margin-bottom: 0.5rem;
        font-weight: 500;
    }

    &::v-deep .header__content {
        label {
            color: var(--ot-color-core-brand);
            font-size: 1rem;
            margin-bottom: 0;
        }
    }
}

.dialog-modal {
    &::v-deep {
        .inline-modal__content {
            padding-bottom: var(--ot-spacing-xl)
        }

        .card-content {
            padding-bottom: var(--ot-spacing-4xl);
        }
    }
}
</style>
