<template>
    <div>
        <ErrorView
            v-if="error"
            :label="error.message"
        />

        <OtPaginationSelect
            v-else-if="shopsList"
            ref="paginationSelect"
            :pagination="shopsList"
            :value="value"
            :data-primary-key="'name'"
            :not-shown-label="$t('dashboard.shops.options_not_shown')"
            :disabled="disabled"
            can-create
            :name-resolver="nameResolver"
            @input="$emit('input', $event)"
            @create="createNewShop"
        />

        <OtSpinner v-else />

        <ShopCreateModal
            v-if="creating"
            :key="id"
            :modal="modalRef"
            @saved="onFormSaved"
        />
    </div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue';
import type {
    CompanyPath, ManagementClient, Shop,
} from '@openticket/lib-management';
import type { Pagination } from '@openticket/lib-crud';
import { OtPaginationSelect } from '@openticket/vue-dashboard-components';
import { injectOrFail } from '../../../services/util';
import ErrorView from '../../../components/ErrorView.vue';
import type { Context } from '../../../services/context';
import { useGenericErrorHandling } from '../../../composables';
import ShopCreateModal from '../../shops/components/ShopCreateModal.vue';
import { useFormModal } from '../../../composables/forms';

type Props = {
    disabled?: boolean,
    value: unknown,
}

type Emits = {
    (e: 'error', error: Error): void,
    (e: 'input', value: unknown): void,
}

defineProps<Props>();
const emit = defineEmits<Emits>();

const { error, handleError } = useGenericErrorHandling();

const context = injectOrFail<Context>('context');
const management = injectOrFail<ManagementClient>('management');

const creating = ref<boolean>(false);
const id = ref<string>('');

const shopsList = ref<Pagination<Shop<CompanyPath<ManagementClient>>> | Pagination<Shop<ManagementClient>>>();

const paginationSelect = ref<InstanceType<typeof OtPaginationSelect> | null>(null);
const modalRef = reactive(useFormModal());

function onFormSaved(guid: string | null) {
    emit('input', guid);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    void paginationSelect.value?.setSelectedLabel(guid, true);
    void populateList();
    modalRef.close();
}

async function populateList() {
    try {
        shopsList.value = context.isCompanyContext()
            ? context.company.model.shops.list()
            : management.shops.list();

        await shopsList.value.initialization;
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}

// Run this on created
void populateList();

async function nameResolver(guid: string): Promise<string> {
    try {
        const shopModel = await management.shops.find(guid);
        return shopModel.$data.name;
    } catch (e) {
        void handleError(e);
    }

    return guid;
}

function createNewShop() {
    try {
        id.value = Math.random().toString();
        creating.value = true;

        modalRef.open();
    } catch (e) {
        void handleError(e);

        if (e instanceof Error) {
            emit('error', e);
        }
    }
}
</script>
