import type { Route, RouteConfig } from 'vue-router';
import { ROLE_WHITELABEL_ADMIN } from '@openticket/lib-auth';
import EventUpdateView from './views/EventUpdateView.vue';
import EventsListView from './views/EventsListView.vue';
import EventsHomeWrapper from './layouts/EventsHomeWrapper.vue';
import { RouterView } from '../../services/util';
import { onCompanyContextSelect } from '../../services/context/contextFilter';

type NestedRoutes = {
    accessMoments: RouteConfig[],
    addonProducts: RouteConfig[],
    dates: RouteConfig[],
    tickets: RouteConfig[],
    wizard: RouteConfig[],
}

export function eventsRoutes(nestedRoutes: NestedRoutes): RouteConfig[] {
    return [
        {
            path: 'events',
            component: RouterView,
            children: [
                {
                    path: '',
                    name: 'events.list',
                    component: EventsListView,
                    meta: {
                        context: 'global',
                        contextIfSet: 'company',
                        title: 'dashboard.document_title.events.list',
                        titleFallback: 'Events',
                        onContextSelect: onCompanyContextSelect,
                    },
                },

                ...nestedRoutes.wizard,

                {
                    path: ':event',
                    name: 'events.home',
                    component: RouterView,
                    redirect: (to: Route) => ({
                        name: 'tickets.list',
                        params: { ...to.params },
                    }),
                    meta: {
                        context: 'event',
                        title: 'dashboard.document_title.events.event',
                        titleFallback: 'Event',
                    },
                    children: [
                        {
                            path: '',
                            component: EventsHomeWrapper,
                            children: [
                                ...nestedRoutes.accessMoments,
                                ...nestedRoutes.addonProducts,
                                ...nestedRoutes.dates,
                                ...nestedRoutes.tickets,
                            ],
                        },
                        {
                            path: 'edit',
                            name: 'events.edit',
                            component: EventUpdateView,
                            meta: {
                                title: 'dashboard.document_title.events.edit.main',
                                titleFallback: 'Event Settings',
                                onContextSelect(id) {
                                    if (!id || typeof id === 'symbol') {
                                        return null;
                                    }
                                    return {
                                        name: 'events.edit.details',
                                        params: { event: id },
                                    };
                                },
                            },
                            redirect: (to: Route) => ({
                                name: 'events.edit.details',
                                params: { ...to.params },
                            }),
                            children: [
                                {
                                    path: 'details',
                                    name: 'events.edit.details',
                                },
                                {
                                    path: 'marketing',
                                    name: 'events.edit.marketing',
                                },
                                {
                                    path: 'admin-actions',
                                    name: 'events.edit.actions',
                                    meta: {
                                        restrictedBy: ROLE_WHITELABEL_ADMIN,
                                    },
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];
}
