<template>
    <TfaCodes
        v-if="recoveryCodes"
        :codes="recoveryCodes"
        @close="close"
    />

    <OTTFAConfirmation
        v-else-if="tfaNeeded && tfaTypes"
        :tfa-types="tfaTypes"
        :require-password="false"
        :hide-logo="tfaSetupProvider.hideLogo"
        :hide-logout="tfaSetupProvider.hideLogout"
        :submitting="!!submitting"
        :error="error"
        @back="backFromTfaConfirmation"
        @submit="submitConfirmation"
    />

    <div v-else>
        <slot
            :submit="submit"
            :error="error"
            :submitting="submitting"
        />
    </div>
</template>

<script setup lang="ts">
import type { AuthClient } from '@openticket/lib-auth';
import type { TFAError, TFATypes } from '@openticket/vue-tfa-confirmation';
import { isAxiosError } from 'axios';
import urlJoin from 'url-join';
import { reactive, ref } from 'vue';
import type VueNotifications from '@openticket/vue-notifications';
import type { VueLocalization } from '@openticket/vue-localization';
import type { PluginsManager } from '../../plugins';
import type { RudderStack } from '../../services/rudderstack';
import { isErrorDescriptionAxiosError } from '../../services/http/axios';
import TfaCodes from './TfaCodes.vue';
import { injectOrFail } from '../../services/util';
import type { TfaSetupProvider } from './types';

type Props = {
    tfaTypes: TFATypes,
}

type Emits = {
    (e: 'close'): void;
}

defineProps<Props>();

const emit = defineEmits<Emits>();

const plugins = injectOrFail<PluginsManager>('plugins');
const notifications = injectOrFail<VueNotifications>('notifications');
const localization = injectOrFail<VueLocalization>('localization');
const tfaSetupProvider = injectOrFail<TfaSetupProvider>('TfaSetupProvider');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const submitting = ref<number>(0);
const recoveryCodes = ref<number[] | null>(null);
const tfaNeeded = ref<{path: string, data: Record<string, number>} | null>(null);

const error = reactive<TFAError>({
    incorrectPassword: null,
    incorrectTFAInput: null,
});

const backFromTfaConfirmation = (): void => {
    reset();
};

const reset = (): void => {
    tfaNeeded.value = null;
    recoveryCodes.value = null;

    error.incorrectPassword = null;
    error.incorrectTFAInput = null;
};

const close = (): void => {
    emit('close');
};

async function submit(path: string, data: Record<string, unknown>): Promise<void> {
    // this.reset(); // TODO @Peter check putting this back

    submitting.value++;

    const auth: AuthClient = await plugins.auth.loading;

    try {
        const response = await auth.$http.put<{ code?: number[]; }>(urlJoin(auth.$path, path), data);

        notifications.success($t(tfaSetupProvider.successMessage));

        if (response && response.data && response.data.code) {
            // TODO remove this once we have more than one recovery method since codes are only returned on
            //  code renew or on the first recovery method
            recoveryCodes.value = response.data.code;
        }

        // TODO @Peter/@Marta Else... What now??
    } catch (e: unknown) {
        if (!isAxiosError(e) || !isErrorDescriptionAxiosError(e)) {
            // TODO @Peter: Ensure all callers handle the 'generic' notification!
            console.warn('submit', { e });
            throw e;
        }

        const errorDescription: string | { [p: string]: string | string[] } = e.response.data.error_description;

        error.incorrectPassword = null;
        error.incorrectTFAInput = null;

        if (e.response.status === 406 && typeof errorDescription !== 'string') {
            // Note, these errors are not expected to arise at the same time and therefore handled one at a time.
            if (errorDescription.password && Array.isArray(errorDescription.password) && errorDescription.password.length) {
                [ error.incorrectPassword ] = errorDescription.password;
                return;
            }

            if (errorDescription.input && Array.isArray(errorDescription.input) && errorDescription.input.length) {
                [ error.incorrectTFAInput ] = errorDescription.input;
                return;
            }

            if (errorDescription.tfa_type) {
                tfaNeeded.value = { path, data };
                return;
            }

            if (errorDescription.tfa_input) {
                tfaNeeded.value = { path, data };

                if (typeof errorDescription.tfa_input !== 'string') {
                    [ error.incorrectTFAInput ] = errorDescription.tfa_input;
                }

                return;
            }
        }

        // TODO @Peter: Ensure all callers handle the 'generic' notification!
        // this.$notifications.warning(this.$t('dashboard.common.state.error'));
        console.warn('submit', { error });
        throw error;
    } finally {
        submitting.value--;
    }
}

async function submitConfirmation(data: {input: string | null, password: string | null, type: string | null}): Promise<void> {
    if (!tfaNeeded.value) {
        // TODO Log (this should not happen)...
        notifications.warning($t('dashboard.common.state.error'));
        return;
    }

    try {
        await submit(tfaNeeded.value.path, {
            ...tfaNeeded.value.data,
            tfa_type: data.type,
            tfa_input: data.input, // TODO @Marta shouldn't password be overwritten here if provided in the data???
        });

        rudderstack.track('vue-dashboard fix tfa submit confirmation success');

        // this.tfaNeeded = null;  // TODO @Peter check putting this back
        // (Note: call should have succeeded, so confirmation has as well. tfa should no longer be needed ?!?
    } catch (e: unknown) {
        rudderstack.track('vue-dashboard fix tfa submit confirmation error');
        // TODO The error should be wrapped here.
        //  Delaying that until lib-auth...
        console.warn('submitConfirmation', { e });
        throw e;
    }
}

// TODO: Remove when moving to Vue 3
const $t = (value: string) => localization.getI18n().t(value);
</script>
