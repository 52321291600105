import type { VueConstructor } from 'vue';
import { AlbatoPlugin } from './albato.plugin';
import { AuthPlugin } from './auth.plugin';
import { ComponentsPlugin } from './components.plugin';
import { ContextPlugin } from './context.plugin';
import { DocumentTitlePlugin } from './documentTitle.plugin';
import { HotkeysPlugin } from './hotkeys.plugin';
import { IntercomPlugin } from './intercom.plugin';
import { LocalizationPlugin } from './localization.plugin';
import { LogPlugin } from './log.plugin';
import { ManagementPlugin } from './management.plugin';
import { RouterPlugin } from './router.plugin';
import { RudderStackPlugin } from './rudderstack.plugin';
import type { Options } from './types';
import { WhitelabelPlugin } from './whitelabel.plugin';
import { RevisorPlugin } from './revisor.plugin';
import { AdminActionsPlugin } from './adminActions.plugin';
import { FeatureFlagPlugin } from './featureFlag.plugin';
import { TemplatesPlugin } from './templates.plugin';
import { UploaderPlugin } from './uploader.plugin';

export class PluginsManager {

    readonly options: Options;

    readonly adminActions: AdminActionsPlugin;
    readonly albato: AlbatoPlugin;
    readonly auth: AuthPlugin;
    readonly components: ComponentsPlugin;
    readonly context: ContextPlugin;
    readonly documentTitle: DocumentTitlePlugin;
    readonly intercom: IntercomPlugin;
    readonly hotkeys: HotkeysPlugin;
    readonly localization: LocalizationPlugin;
    readonly log: LogPlugin;
    readonly management: ManagementPlugin;
    readonly featureFlag: FeatureFlagPlugin;
    readonly revisor: RevisorPlugin;
    readonly router: RouterPlugin;
    readonly rudderstack: RudderStackPlugin;
    readonly templates: TemplatesPlugin;
    readonly uploader: UploaderPlugin;
    readonly whitelabel: WhitelabelPlugin;

    get all(): Promise<void> {
        return Promise.all([
            this.adminActions.loading,
            this.auth.loading,
            this.components.loading,
            this.context.loading,
            this.intercom.loading,
            this.documentTitle.loading,
            this.hotkeys.loading,
            this.localization.loading,
            this.log.loading,
            this.management.loading,
            this.featureFlag.loading,
            this.router.loading,
            this.rudderstack.loading,
            this.templates.loading,
            this.uploader.loading,
            this.whitelabel.loading,
        ]).then(() => { /* No-op */ });
    }

    get errorCount(): number {
        return [
            this.adminActions,
            this.auth,
            this.components,
            this.context,
            this.documentTitle,
            this.intercom,
            this.hotkeys,
            this.localization,
            this.log,
            this.management,
            this.featureFlag,
            this.router,
            this.rudderstack,
            this.templates,
            this.uploader,
            this.whitelabel,
        ].reduce((carry: number, plugin) => carry + plugin.errors.length, 0);
    }

    constructor(options: Options = {}) {
        this.options = options;
        this.adminActions = new AdminActionsPlugin();
        this.albato = new AlbatoPlugin();
        this.auth = new AuthPlugin();
        this.components = new ComponentsPlugin();
        this.context = new ContextPlugin();
        this.documentTitle = new DocumentTitlePlugin();
        this.intercom = new IntercomPlugin();
        this.hotkeys = new HotkeysPlugin();
        this.localization = new LocalizationPlugin();
        this.log = new LogPlugin();
        this.management = new ManagementPlugin();
        this.featureFlag = new FeatureFlagPlugin();
        this.revisor = new RevisorPlugin();
        this.router = new RouterPlugin();
        this.rudderstack = new RudderStackPlugin();
        this.templates = new TemplatesPlugin();
        this.uploader = new UploaderPlugin();
        this.whitelabel = new WhitelabelPlugin();
    }

    /**
     * This method starts the installation of all plugins.
     * It is called by the Vue plugin installer (`Vue.use`) before the creation of the app.
     *
     * See: `main.ts`
     */
    install(Vue: VueConstructor): void {
        void this.log.install(this);

        // Explicitly do NOT await install calls here.
        // The loading property of the plugin can be awaited whenever needed.
        // Any install method can define and wait for its own dependencies.
        // Do be careful though to avoid circular dependencies!
        void this.adminActions.install(this, Vue);
        void this.albato.install(this, Vue);
        void this.auth.install(this, Vue);
        void this.components.install(this, Vue);
        void this.context.install(this, Vue);
        void this.documentTitle.install(this);
        void this.intercom.install(this, Vue);
        void this.hotkeys.install(this);
        void this.localization.install(this, Vue);
        // void this.log.install(this); has been pulled up.
        void this.management.install(this, Vue);
        void this.featureFlag.install(this, Vue);
        void this.revisor.install(this, Vue);
        void this.router.install(this, Vue);
        void this.rudderstack.install(this, Vue);
        void this.templates.install(this, Vue);
        void this.uploader.install(this, Vue);
        void this.whitelabel.install(this, Vue);

        Vue.mixin({ provide: { plugins: this } });
    }

}
