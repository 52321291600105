<template>
    <div class="debug-plugins__localization">
        <code
            class="debug-plugins__localization__status full-width"
        >
            <span>Status:</span>

            <span v-if="loading">Loading</span>
            <span v-else>Ready</span>
        </code>

        <code class="debug-plugins__localization__locale full-width">
            <span>Current locale:</span>
            <span>{{ currentLocale }}</span>
        </code>

        <code class="debug-plugins__localization__examples full-width">
            <span class="row">Examples:</span>
            <span class="row"><span>[timeLong]</span><span v-if="$l">{{ $l.timeLong(date) }}</span></span>
            <span class="row"><span>[number]</span><span v-if="$l">{{ $l.number(date.getTime()) }}</span></span>
            <span class="row"><span>[currency]</span><span v-if="$l">{{ $l.currency(1234, currency) }}</span></span>
            <span class="row"><span>[translate]</span><span v-if="$t">
                {{ $t('common.copyright', { year: new Date().getFullYear() }) }}
            </span></span>
        </code>

        <code class="debug-plugins__localization__locales full-width">
            <span>Available locales</span>
            <span>
                <span
                    v-for="locale in supportedLocales"
                    :key="locale"
                >{{ locale }}<br></span>
            </span>
        </code>
    </div>
</template>

<script lang="ts" setup>
import type { VueLocalization } from '@openticket/vue-localization';
import {
    computed, onBeforeUnmount, onMounted, ref,
} from 'vue';
import type { PluginsManager } from '../../../../plugins';
import { injectOrFail } from '../../../../services/util';

const plugins = injectOrFail<PluginsManager>('plugins');
const loading = ref<boolean>(true);
const localization = ref<VueLocalization | null>(null);
const date = ref<Date>(new Date());
const dateInterval = ref<NodeJS.Timeout>();

const currentLocale = computed<string>(() => localization.value?.locale.locale || '');
const supportedLocales = computed<string[]>(() => Object.keys(localization.value?.supportedLocales || {}));
const currency = computed<string>(() => localization.value?.defaultCurrency.currency || 'EUR');

onMounted(() => {
    dateInterval.value = setInterval(() => {
        date.value = new Date();
    }, 250);
});

onBeforeUnmount(() => {
    clearInterval(dateInterval.value);
});

void (async () => {
    try {
        localization.value = await plugins.localization.loading;
    } finally {
        loading.value = false;
    }
})();

</script>

<style lang="scss" scoped>
.debug-plugins__localization {
    & > code {
        display: flex;
        justify-content: space-between;
        margin-top: 1px;

        &.debug-plugins__localization__examples {
            flex-direction: column;
            justify-content: flex-start;

            & > .row {
                display: flex;
                justify-content: space-between;
            }
        }
    }
}
</style>
