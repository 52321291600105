import type { PostHog } from 'posthog-js';
import posthog from 'posthog-js';
import { FeatureFlagInitError } from './errors';
import type { FeatureFlag, DefinedFlag } from './types';
import { definedFlags } from './types';

export class Posthog implements FeatureFlag {

    private instance: PostHog | undefined;

    async init(url: string, token: string): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                this.instance = posthog.init(
                    token,
                    {
                        api_host: url,
                        bootstrap: {
                            featureFlags: definedFlags,
                        },
                        capture_pageview: false,
                        autocapture: false,
                        disable_session_recording: true,
                        person_profiles: 'identified_only',
                    },
                );
            } catch (e: unknown) {
                if (e instanceof Error) {
                    reject(new FeatureFlagInitError(e));
                }

                throw e;
            }

            if (!this.instance) {
                reject(new FeatureFlagInitError());
                return;
            }

            this.instance.onFeatureFlags((flags: string[]) => {
                this.checkForDifferenceInDefinedFlags(flags);
                resolve();
            });
        });
    }

    isFeatureEnabled(key: DefinedFlag): boolean {
        if (!this.instance) {
            // Do not crash when the instance is not initialized.
            return definedFlags[key] === true;
        }

        // Posthog uses the default value of a flag set in bootstrap config of init.
        return this.instance.isFeatureEnabled(key) === true;
    }

    private checkForDifferenceInDefinedFlags(flags: string[]): void {
        const definedFlagKeys = Object.keys(definedFlags);
        const undefinedFlags = flags.filter((flag) => !definedFlagKeys.includes(flag));

        if (undefinedFlags.length > 0) {
            console.warn('Flags not defined in dashboard:', undefinedFlags);
        }
    }

    reloadFeatureFlags(): Promise<void> {
        return new Promise((resolve, reject) => {
            if (!this.instance) {
                console.warn('Could not reload flags because Posthog was not initialized properly.');
                reject();
                return;
            }

            this.instance.onFeatureFlags((flags: string[]) => {
                this.checkForDifferenceInDefinedFlags(flags);
                resolve();
            });
            this.instance.reloadFeatureFlags();

            setTimeout(() => {
                console.warn('Posthog took too long to reload flags.');
                reject();
            }, 5000);
        });
    }

}
