import { OtError, UnknownError } from '@openticket/lib-log';

export class FeatureFlagInitError extends OtError {

    constructor(cause?: Error) {
        super('FeatureFlagInitError', 'dashboard.errors.plugins.feature_flag.init', 'Failed to initialize feature flags.');

        if (cause) {
            this.causedBy(new UnknownError(cause));
        }

        this.captureStackTrace(FeatureFlagInitError);
    }

}
