<template>
    <OtFilters
        :categories-to-hide="filterCategoriesToHide"
        :categories-order="filterCategoriesOrder"
        :labels="$t('models.event')"
        :pagination="events"
        :translations="$t('dashboard.common.filters')"
        :view-filter="{ label: companyViewFilterSlug }"
    >
        <template #tag__past="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :values="[ { label: $t('models.event.scopes.past.label'), filtertype: 'scope' } ]"
                @close="(filtertype) => events.setFilter(filter.attribute, filtertype, null)"
            />
        </template>

        <template #tag__upcoming="{ filter }">
            <OtFilterTag
                v-if="filter.active.scope !== null"
                closable
                :values="[ { label: $t('models.event.scopes.upcoming.label'), filtertype: 'scope' } ]"
                @close="(filtertype) => events.setFilter(filter.attribute, filtertype, null)"
            />
        </template>

        <OtFilterCategoryCustom
            id="event_period"
            v-slot="{ state }"
            :attributes="['past', 'upcoming']"
            icon="calendar"
            label="Event period"
        >
            <OtInputRadioGroup
                :value="eventPeriodValue(state)"
                :options="eventPeriodOptions"
                @input="(val) => eventPeriodUpdate(state, val)"
            />
        </OtFilterCategoryCustom>
    </OtFilters>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import type { Pagination } from '@openticket/lib-crud';
import type { Event, ManagementClient } from '@openticket/lib-management';
import type { VueLocalization } from '@openticket/vue-localization';
import { injectOrFail } from '../../../services/util';
import type { Context } from '../../../services/context';

type Props = {
    events: Pagination<Event<ManagementClient>>;
}

defineProps<Props>();

const context = injectOrFail<Context>('context');
const localization = injectOrFail<VueLocalization>('localization');

const companyViewFilterSlug = computed<string>(() => (context.isCompanyContext()
    ? context.company.name
    : localization.getI18n().t('dashboard.sidebar.context_filter.company.all_companies') as string
));

const filterCategoriesToHide = [
    'contact_email',
    'contact_name',
    'contact_phone',
    'created_at',
    'currency',
    'email_info',
    'gui_mode',
    'updated_at',
    'locale',
    'retrievable_after',
    'status',
    'status_until',
    'type',
    'visitor_contact_email',
    'visitor_contact_phone',
    'visitor_contact_url',
    'website',
];

const filterCategoriesOrder = [
    'event_period',
    'start',
    'end',
    'name',
];

type EventPeriodState = {
    past: { scope: string | null },
    upcoming: { scope: string | null },
}

const eventPeriodOptions = {
    upcoming: { label: localization.getI18n().t('models.event.scopes.upcoming.label') },
    past: { label: localization.getI18n().t('models.event.scopes.past.label') },
    all: { label: localization.getI18n().t('dashboard.events.list.filters.all_events') },
};

function eventPeriodValue(state: EventPeriodState): keyof typeof eventPeriodOptions {
    if (state.past.scope) {
        return 'past';
    } if (state.upcoming.scope) {
        return 'upcoming';
    }

    return 'all';
}

function eventPeriodUpdate(state: EventPeriodState, value: keyof typeof eventPeriodOptions) {
    state.past.scope = value === 'past' ? '1' : null;
    state.upcoming.scope = value === 'upcoming' ? '1' : null;
}
</script>
