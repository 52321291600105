export type DefinedFlag = keyof typeof definedFlags;

// These define the typing of the flags, and are also used as the default values.
export const definedFlags = {
    Test_userRole: false,
    ShowHenk: false,
};

export interface FeatureFlag {
    init(url: string, token: string): Promise<void>;
    isFeatureEnabled(key: DefinedFlag): boolean;
    reloadFeatureFlags(): Promise<void>;
}
