<template>
    <div class="event-wizard-shop">
        <div class="event-wizard-shop__header">
            <OtDocsSection
                :label="$t('dashboard.common.read_docs')"
                type="default"
            >
                <h1>{{ $t('dashboard.events.wizard.steps.shop.docs.title') }}</h1>
                <span>{{ $t('dashboard.events.wizard.steps.shop.docs.description') }}</span>
            </OtDocsSection>
        </div>

        <div class="event-wizard-shop__content">
            <div
                v-if="isTicketListEmpty"
                class="event-wizard-shop__content__no-tickets"
            >
                <div>
                    <h1>{{ $t('dashboard.events.wizard.steps.shop.selection.empty.title') }}</h1>
                    <p>{{ $t('dashboard.events.wizard.steps.shop.selection.empty.description') }}</p>
                </div>
            </div>

            <div
                v-else
                class="event-wizard-shop__content__shop-selection"
            >
                <h3>{{ $t('dashboard.events.wizard.steps.shop.selection.title') }}</h3>
                <span>
                    {{ $t('dashboard.events.wizard.steps.shop.selection.description') }}
                </span>

                <div class="event-wizard-shop__content__shop-selection__selector">
                    <FancyRadio
                        :value="ticketAvailability"
                        :options="ticketAvailabilityOptions"
                        data-testid="event-wizard-shop-select-availability"
                        @checked="updateTicketAvailability"
                    >
                        <template #available>
                            <ShopsSelect
                                v-model="selectedShop"
                                :disabled="ticketAvailability !== 'available'"
                                data-testid="event-wizard-shop-select-shop"
                            />
                        </template>
                    </FancyRadio>
                </div>

                <div
                    v-if="selectedShop"
                    class="event-wizard-shop__content__shop-selection__tickets"
                >
                    <h3>{{ $t('dashboard.events.wizard.steps.shop.selection.tickets.title') }}</h3>
                    <span>
                        {{ $t('dashboard.events.wizard.steps.shop.selection.tickets.description') }}
                    </span>
                    <div class="event-wizard-shop__content__shop-selection__tickets__checkboxes">
                        <OtInputCheckbox
                            class="event-wizard-shop__content__shop-selection__tickets__checkboxes-all"
                            data-testid="event-wizard-shop-select-ticket-checkboxes-all"
                            :label="$t('dashboard.events.wizard.steps.shop.selection.tickets.select_all.label')"
                            :value="allTicketsSelected"
                            @input="toggleAllTickets()"
                        />
                        <div class="event-wizard-shop__content__shop-selection__tickets__checkboxes-separator ot-text-tiny">
                            {{ $t('dashboard.events.wizard.steps.shop.selection.tickets.separator.text') }}
                        </div>
                        <OtInputCheckboxGroup
                            v-model="selectedTickets"
                            class="event-wizard-shop__content__shop-selection__tickets__checkboxes-individual"
                            :options="ticketOptions"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import type { Event, ManagementClient, Ticket } from '@openticket/lib-management';
import type { VueLocalization } from '@openticket/vue-localization';
import FancyRadio from '../../../../components/FancyRadio.vue';
import { injectOrFail } from '../../../../services/util';
import ShopsSelect from '../ShopsSelect.vue';
import { useGenericErrorHandling } from '../../../../composables';
import type { WizardProvide } from '../../types';

const { handleError } = useGenericErrorHandling();

const management = injectOrFail<ManagementClient>('management');
const wizardProvide = injectOrFail<WizardProvide>('wizardProvide');
const localization = injectOrFail<VueLocalization>('localization');

const ticketAvailability = ref<string>('');
const selectedShop = ref<string | null>();

const ticketOptions = ref<Record<string, { label: string }>>();
const selectedTickets = ref<string[] | null>();

const ticketAvailabilityOptions = [
    {
        value: 'available',
        label: $t('dashboard.events.wizard.steps.tickets.upsert.form.availability.available.label'),
        description: $t('dashboard.events.wizard.steps.tickets.upsert.form.availability.available.description'),
    }, {
        value: 'not-available',
        label: $t('dashboard.events.wizard.steps.tickets.upsert.form.availability.not_available.label'),
        description: $t(
            'dashboard.events.wizard.steps.tickets.upsert.form.availability.not_available.description',
        ),
    },
];

const isNextButtonDisabled = computed(() => {
    // Enable button when there are no tickets to be selected.
    if (isTicketListEmpty.value) {
        return false;
    }

    // Disable button when user has selected an option but has not yet selected a shop or/and tickets to sell.
    if (!ticketAvailability.value || (ticketAvailability.value === 'available' && (!selectedShop.value || (!selectedTickets.value || selectedTickets.value.length <= 0)))) {
        return true;
    }

    return false;
});

const isTicketListEmpty = computed(() => {
    if (!wizardProvide.eventTickets.pagination.value
        || !wizardProvide.eventTickets.pagination.value.total
        || wizardProvide.eventTickets.pagination.value.total <= 0) {
        return true;
    }

    return false;
});

const allTicketsSelected = computed(() => Object.keys(ticketOptions.value ?? []).length
    === Object.values(selectedTickets.value ?? []).length);

void (() => {
    try {
        if (wizardProvide.eventForm.model.id) {
            const parseTicketOptions = (data: Ticket<Event<ManagementClient>>[]) => {
                const map: { [key: string]: { label: string } } = {};
                const { currency } = wizardProvide.eventForm.model.$raw;
                data.forEach((ticket, index) => {
                    if (ticket.id) {
                        const getPriceWithCurrency = () => {
                            const cents = data[index].$data.min_price;
                            return localization.formatters.currency(cents ?? 0, currency);
                        };
                        const label = `<div>${data[index].$data.name}</div> <span class="ot-text-body">${getPriceWithCurrency()}</span>`;
                        map[ticket.id] = { label };
                    }
                });
                return map;
            };

            ticketOptions.value = parseTicketOptions(wizardProvide.eventTickets.pagination.value?.records ?? []);
        }
    } catch (e) {
        void handleError(e);
    }
})();

function toggleAllTickets() {
    selectedTickets.value = allTicketsSelected.value ? undefined : Object.keys(ticketOptions.value ?? {});
}

function updateTicketAvailability(value: string) {
    ticketAvailability.value = value;

    if (value === 'not-available') {
        selectedShop.value = null;
        selectedTickets.value = null;
    }
}

async function linkTicketsToShop(): Promise<{ result: boolean, redirectTo?: 'shops' | 'events', guid?: string | null }> {
    if (isTicketListEmpty.value || ticketAvailability.value === 'not-available') {
        return Promise.resolve({ result: true, redirectTo: 'events', guid: wizardProvide.eventForm.model.id });
    }

    if (ticketAvailability.value === 'available' && selectedShop.value && selectedTickets.value) {
        // Fetch select shop model
        const shopModel = await management.shops.find(selectedShop.value);

        // Fetch selected ticket models
        const ticketModels = [];
        for (let index = 0; index < selectedTickets.value.length; index++) {
            const ticketModel = await management.tickets.find(selectedTickets.value[index]);
            ticketModels.push(ticketModel);
        }

        // Use linkMulti to link ticket models to shop model
        await shopModel.tickets.linkMulti(...ticketModels);

        return Promise.resolve({ result: true, redirectTo: 'shops', guid: shopModel.id });
    }

    return Promise.resolve({ result: false });
}

// TODO: Remove when upgraded to Vue 3
function $t(slug: string, values?: Record<string, unknown>) {
    return localization.getI18n().t(slug, values);
}

defineExpose({
    linkTicketsToShop,
    isNextButtonDisabled,
});
</script>

<style scoped lang="scss">
.event-wizard-shop {
    &__header {
        padding: var(--ot-spacing-xl) 0;
        border-bottom: 1px solid var(--ot-color-core-light-accent-tertiary);
        margin-bottom: var(--ot-spacing-xl);

        h1 {
            color: var(--ot-color-core-light-foreground-primary);
            margin-bottom: var(--ot-spacing-xs);
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }
    }

    &__content {
        margin: var(--ot-spacing-xl) 0;

        h3 {
            color: var(--ot-color-core-light-foreground-primary);
            margin-bottom: var(--ot-spacing-2xs);
        }

        span {
            color: var(--ot-color-core-light-foreground-secondary);
        }

        &__no-tickets {
            height: 200px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            text-align: center;
        }

        &__shop-selection {
            &__selector {
                margin: var(--ot-spacing-xl) 0;
            }

            &__tickets {
                margin-top: var(--ot-spacing-xl);
                padding-top: var(--ot-spacing-xl);
                border-top: 1px solid var(--ot-color-core-light-accent-tertiary);

                &__checkboxes {
                    padding-top: var(--ot-spacing-xl);

                    &-all {
                        border: 2px solid var(--ot-color-core-light-accent-tertiary);
                        padding: var(--ot-spacing-sm) var(--ot-spacing-xl);
                        border-radius: var(--ot-input-radius);
                    }

                    &-separator {
                        display: flex;
                        align-items: center;
                        color: var(--ot-color-core-light-accent-primary);
                        gap: var(--ot-spacing-sm);
                        text-transform: uppercase;

                        &::before, &::after {
                            flex: 1;
                            content: '';
                            padding: 1px;
                            background-color: var(--ot-color-core-light-accent-tertiary);
                            margin: var(--ot-spacing-xl) 0;
                        }
                    }

                    &-individual {
                        &::v-deep .ot-checkbox {
                            border: 2px solid var(--ot-color-core-light-accent-tertiary);
                            padding: var(--ot-spacing-sm) var(--ot-spacing-xl);
                            border-radius: var(--ot-input-radius);

                            &-label {
                                display: flex!important;
                                flex-direction: row;
                                justify-content: space-between;
                                overflow: hidden;
                                gap: var(--ot-spacing-sm);

                                & > div {
                                    flex: 1;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                }

                                & > span {
                                    color: var(--ot-color-accent-primary);
                                }
                            }
                        }
                    }
                }

            }

        }
    }
}
</style>
