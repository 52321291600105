<template>
    <div
        v-if="formData || notLoggedOutError || tokenError"
        class="ot-card complete-profile"
    >
        <div v-if="notLoggedOutError">
            <ModalHeader hide-padding>
                <template #logout>
                    <button
                        class="is-text is-small"
                        type="button"
                        :title="$t('dashboard.invitation.invalid.logout.title')"
                        @click="logoutAndReload"
                    >
                        <OtIcon
                            class="ot-button-icon--left"
                            type="logout"
                        />
                        {{ $t('dashboard.invitation.invalid.logout.text') }}
                    </button>
                </template>
            </ModalHeader>

            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(notLoggedOutError)"
                    type="is-warning"
                    icon="warning"
                />
            </div>

            <div class="complete-profile__footer">
                <button
                    class="ot-button"
                    type="button"
                    :title="$t('dashboard.invitation.invalid.dashboard.title')"
                    @click="$router.push({name: 'auth.login'})"
                >
                    {{ $t('dashboard.invitation.invalid.dashboard.text') }}
                    <OtIcon
                        class="ot-button-icon--right"
                        type="arrow-right"
                    />
                </button>
            </div>
        </div>

        <div v-else-if="tokenError">
            <ModalHeader hide-padding />
            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtAside
                    :title="$t('dashboard.invitation.invalid.invalid')"
                    :description="$t(tokenError)"
                    type="is-warning"
                    icon="warning"
                />
            </div>
        </div>

        <div v-else-if="formData">
            <ModalHeader hide-padding />
            <div class="complete-profile__header">
                <h1>{{ $t('dashboard.invitation.complete_profile.title') }}</h1>
            </div>

            <div class="complete-profile__content">
                <OtForm form="">
                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.your_name')"
                            :error="$t(formErrors.name, validationRules.name)"
                            :required="rules.name && rules.name.includes('required')"
                            :optional="rules.name && rules.name.includes('optional')"
                        >
                            <OtInput
                                v-model="formData.name"
                                class="input"
                                type="text"
                                :invalid="!!formErrors.name"
                            />
                        </InputField>
                    </OtFormRow>

                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.create_password')"
                            :error="$t(formErrors.new_password,validationRules.new_password)"
                            :required="rules.new_password && rules.new_password.includes('required')"
                            :optional="rules.new_password && rules.new_password.includes('optional')"
                        >
                            <OtInput
                                v-model="formData.new_password"
                                class="input"
                                type="password"
                                :invalid="!!formErrors.new_password"
                                data-testid="password-field"
                            />
                        </InputField>
                    </OtFormRow>

                    <OtFormRow>
                        <InputField
                            :label="$t('dashboard.invitation.complete_profile.form.confirm_password')"
                            :error="$t(formErrors.new_password_confirmation, validationRules.new_password_confirmation)"
                            :required="rules.new_password_confirmation && rules.new_password_confirmation.includes('required')"
                            :optional="rules.new_password_confirmation && rules.new_password_confirmation.includes('optional')"
                        >
                            <OtInput
                                v-model="formData.new_password_confirmation"
                                class="input"
                                type="password"
                                :invalid="!!formErrors.new_password_confirmation"
                                data-testid="confirm-password-field"
                            />
                        </InputField>
                    </OtFormRow>

                    <div class="terms">
                        <InputField
                            :error="formErrors.accept_tos ? $t(formErrors.accept_tos, validationRules.accept_tos) : []"
                        >
                            <OtInput
                                id="terms"
                                v-model="formData.accept_tos"
                                class="input terms-label__checkbox"
                                type="checkbox"
                            />

                            <i18n
                                path="dashboard.invitation.complete_profile.form.terms.first"
                                class="ot-input-label ot-checkbox-label terms-label__label"
                            >
                                <template #tos>
                                    <a
                                        class="terms-tos-first ot-link"
                                        :href="$whitelabel.dashboard.terms_url || 'https://eventix.io/documents/2018_EN_organiser_terms.pdf?lang=en_GB'"
                                        target="_blank"
                                    >
                                        {{ $t('dashboard.invitation.complete_profile.form.terms.tos') }}
                                    </a>
                                </template>

                                <template #entity>
                                    <span class="terms-entity terms-entity-first">
                                        {{ $whitelabel.name }}
                                    </span>

                                    <span
                                        v-if="rules.accept_tos && rules.accept_tos.includes('required')"
                                        class="ot-input-label--required"
                                    >
                                        *
                                    </span>
                                </template>
                            </i18n>
                        </InputField>
                    </div>
                </OtForm>
            </div>

            <div class="complete-profile__footer">
                <button
                    class="ot-button is-large"
                    type="button"
                    :disabled="formNotCompleted"
                    :title="$t('dashboard.invitation.complete_profile.form.button.title')"
                    @click="submit()"
                >
                    <OtIcon
                        class="ot-button-icon--left"
                        type="check"
                    />
                    {{ $t('dashboard.invitation.complete_profile.form.button.text') }}
                </button>
            </div>
        </div>
    </div>
    <OtSpinner v-else />
</template>

<script setup lang="ts">
import type { Whitelabel } from '@openticket/lib-whitelabels';
import type { AuthClient } from '@openticket/lib-auth';
import { isAxiosError } from 'axios';
import type { AxiosResponse } from 'axios';
import urlJoin from 'url-join';
import { computed, reactive, ref } from 'vue';
import { OtInput } from '@openticket/vue-input';
import type { TranslateResult, VueLocalization } from '@openticket/vue-localization';
import { useRoute, useRouter } from 'vue-router/composables';
import ModalHeader from '../../../components/modal/ModalHeader.vue';
import { isErrorDescriptionAxiosError } from '../../../services/http/axios';
import InputField from '../../../components/form/InputField.vue';
import { injectOrFail } from '../../../services/util';
import type { RudderStack } from '../../../services/rudderstack';

type FormData = {
    name: string;
    new_password: string;
    phone: string | null;
    new_password_confirmation: string;
    accept_tos: boolean;
}

type FormErrors = {
    [key in keyof Omit<FormData, 'phone'>]: string | null
}

type FormRules = {
    [key in keyof Omit<FormData, 'phone'>]: string[]
}

type ValidationRules = {
    [key in keyof Omit<FormData, 'phone'>]: { [key: string ]: TranslateResult}
}

const auth = injectOrFail<AuthClient>('auth');
const localization = injectOrFail<VueLocalization>('localization');
const whitelabel = injectOrFail<Whitelabel>('whitelabel');
const rudderstack = injectOrFail<RudderStack>('rudderstack');

const tokenError = ref<string | null>(null);
const notLoggedOutError = ref<string | null>(null);
const formData = ref<FormData | null>(null);

const formErrors = reactive<FormErrors>({
    name: null,
    new_password: null,
    new_password_confirmation: null,
    accept_tos: null,
});
const rules = ref<FormRules>({
    name: [],
    new_password: [],
    new_password_confirmation: [],
    accept_tos: [],
});
const validationRules = reactive<ValidationRules>({
    name: { attribute: localization.getI18n().t('dashboard.invitation.complete_profile.form.attributes.name') },
    new_password: { attribute: localization.getI18n().t('dashboard.invitation.complete_profile.form.attributes.password') },
    new_password_confirmation: { attribute: localization.getI18n().t('dashboard.invitation.complete_profile.form.attributes.password_confirmation') },
    accept_tos: { attribute: localization.getI18n().t('dashboard.invitation.complete_profile.form.attributes.accept_tos') },
});

const formNotCompleted = computed<boolean>(() => !formData.value
            || !formData.value.accept_tos
            || !formData.value.new_password
            || !formData.value.new_password_confirmation
            || !formData.value.name);

const route = useRoute();
const router = useRouter();

void (async (): Promise<void> => {
    if (!auth) {
        return;
    }

    rudderstack.track('vue-dashboard fix finalize profile init');

    await checkTokenAndUser();
    // TODO move to a form and remove fetching rules locally
    rules.value = (await auth.$http.get<FormRules>(urlJoin(auth.$path, 'finalize', 'rules'))).data;

    rules.value.name.forEach((rule) => {
        const split = rule.split(':');
        if (split.length >= 2) {
            [ , validationRules.name[split[0]] ] = split;
        }
    });

    rules.value.new_password.forEach((rule) => {
        const split = rule.split(':');
        if (split.length >= 2) {
            [ , validationRules.new_password[split[0]] ] = split;
        }
    });
})();

async function checkTokenAndUser(): Promise<void> {
    notLoggedOutError.value = null;
    tokenError.value = null;

    try {
        // TODO - @openticket/lib-auth
        const response: AxiosResponse<{ user: { guid: string; name: string; } }> = await auth.$http.get(
            urlJoin(auth.$path, 'finalize', route.params.token),
            { headers: { Accept: 'application/json' } },
        );

        const { user }: { user: { guid: string; name: string; } | null } = response.data;

        if (!user) {
            return;
        }

        formData.value = {
            accept_tos: false,
            name: user.name,
            phone: null,
            new_password: '',
            new_password_confirmation: '',
        };
    } catch (error: unknown) {
        if (!isAxiosError(error)
                || !isErrorDescriptionAxiosError(error)
                || typeof error.response.data.error_description !== 'string'
                || error.response.status >= 500
        ) {
            void router.push({ name: 'error' });

            return;
        }

        if (error.response.status === 400) {
            notLoggedOutError.value = error.response.data.error_description;
        } else if (error.response.status === 404) {
            tokenError.value = error.response.data.error_description;
        }

        // TODO @PETER: REVIEW -> Ignore fallthrough all error conditions above????
        //  lib-auth -> should at least re-trow??
    }
}

async function logoutAndReload(): Promise<void> {
    try {
        // TODO - @openticket/lib-auth
        await Promise.all([
            auth.$token.logout(), // TODO @Peter - lib-auth add a 'clear tokens' (e.g. logout without hooks) method.
            auth.$http.get(urlJoin(auth.$path, 'sessions', 'logout')),
        ]);
    } catch (e: unknown) {
        if (isAxiosError(e) && e.response && (
            e.response.status === Number(import.meta.env.VITE_LOGOUT_REDIRECT_STATUS)
                || e.response.status === 355
        )) {
            void checkTokenAndUser();
        } else {
            await router.push({ name: 'error' });
        }
    }
}

async function submit(): Promise<void> {
    if (!auth) {
        return;
    }

    try {
        // TODO - @openticket/lib-auth
        await auth.$http.post(urlJoin(auth.$path, 'finalize', route.params.token), formData.value);
    } catch (e: unknown) {
        if (!isAxiosError(e)
                || !isErrorDescriptionAxiosError(e)
        ) {
            void router.push({ name: 'error' });

            return;
        }

        if (typeof e.response.data.error_description === 'string' && e.response.status === 400) {
            notLoggedOutError.value = e.response.data.error_description;
        } else if (typeof e.response.data.error_description === 'string' && e.response.status === 404) {
            tokenError.value = e.response.data.error_description;
        } else if (e.response.status === 406) {
                interface errorAnswers {
                    error_description: {
                        name: string[],
                        new_password: string[],
                        new_password_confirmation: string[],
                        accept_tos: string[]
                    }
                }

                const { error_description } = e.response.data as errorAnswers;
                if (error_description.name && error_description.name.length > 0) {
                    [ formErrors.name ] = error_description.name;
                }
                if (error_description.new_password && error_description.new_password.length > 0) {
                    [ formErrors.new_password ] = error_description.new_password;
                }
                if (error_description.accept_tos && error_description.accept_tos.length > 0) {
                    [ formErrors.accept_tos ] = error_description.accept_tos;
                }
        }
        return;
    }

    if (whitelabel.dashboard.login_url) {
        window.location.href = whitelabel.dashboard.login_url;
    } else {
        void router.push({ name: 'auth.login' });
    }
}
</script>

<style lang="scss" scoped>
.complete-profile {

    &__header{
        margin-bottom: var(--ot-spacing-lg);

        &__top {
            display: flex;
            margin-bottom: var(--ot-spacing-lg);

            &__logo {
                max-width: 40%;
                text-align: left;
            }

            &__logout {
                color: var(--ot-color-core-brand);
                margin: auto 0 auto auto;
            }
        }

        &__title {
            text-align: center;
        }
    }

    &__content {
        margin-bottom: var(--ot-spacing-3xl);

        .terms {
            &::v-deep .terms-label__checkbox {
                flex: initial;
                width: auto;
                margin-right: 0;
            }

            &-label__label {
                cursor: default;
            }
        }
    }

    &__footer .ot-button {
        &.is-text {
            color: var(--ot-color-core-brand);
        }

        width: 100%;
        margin-bottom: var(--ot-spacing-default);
    }
}
</style>
